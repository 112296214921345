import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { INNodeModel } from 'src/app/@core/model/asset.model';
import { assetsActionTypes } from '../actions/asset.actions';

export const userAssetsFeatureKey = 'userAssets';

export interface UserAssetsState extends EntityState<INNodeModel> {
  error: string;
}

export const adapter: EntityAdapter<INNodeModel> = createEntityAdapter<INNodeModel>(
  {
    selectId: (asset) => asset.uuid + asset.id
  }
);

export const initialState = adapter.getInitialState({
  error: ''
});

export const reducer = createReducer(
  initialState,

  on(assetsActionTypes.addAsset, (state, action) => {
    if (!action.asset) {
      return {
        ...state,
        loading: false,
        error: ''
      };
    }
    return {
      ...adapter.addOne(Object.assign({}, action.asset), state),
      loading: false,
      error: ''
    };
  }),
  on(assetsActionTypes.removeAsset, (state, action) => {
    return {
      ...adapter.removeOne(action.asset?.uuid + action.asset?.id, state),
      loading: false,
      error: ''
    }
  }),
  on(assetsActionTypes.reset, () => {
    return Object.assign([], initialState);
  })
);

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal
} = adapter.getSelectors();

export const getError = (state: UserAssetsState): string => state.error;

